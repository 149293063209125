<template>
  <div />
</template>
<script>
import { Loader } from "@googlemaps/js-api-loader";

export default {
    name: "GoogleLookupWrapper",
    components: {},
    props: {
        value: {
            type: String,
            default: "",
        },
        settings: {
            type: Object,
            default: () => null,
        },
    },
    data() {
        return {
            inputVal: "",
            googleLoader: {},
        };
    },
    watch: {
        settings: {
            deep: true,
            handler() {
                this.initGoogleScript();
            },
        },
    },
    mounted() {
        if (this.settings.key && this.settings.key.length > 0)
            this.initGoogleScript();
    },
    methods: {
        initGoogleScript() {
            this.googleLoader = new Loader({
                apiKey: this.settings.key,
                version: "weekly",
                libraries: ["places"],
            });

            this.configureAddressChanged();
        },

        configureAddressChanged() {
            var origin_input = document.getElementById("address");
            this.googleLoader
            .load()
            .then((google) => {
                var autocomplete =
                    new google.maps.places.Autocomplete(
                        origin_input
                    );
                autocomplete.addListener("place_changed", () => {
                    const place = autocomplete.getPlace();
                    this.$emit("selected", place);
                });
            })
            .catch(e => {
                this.$emit("loadError");
            });
        },
    },
};
</script>

<style>
#gf-epl-address-details input[type="text"] {
    margin: 0;
    outline: 0;
    tap-highlight-color: hsla(0, 0%, 100%, 0);
    border: 1px solid rgba(34, 36, 38, 0.2);
    color: #000;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 0 0 transparent inset;
    box-shadow: inset 0 0 0 0 transparent;
    -webkit-transition: color 0.1s ease, border-color 0.1s ease;
    transition: color 0.1s ease, border-color 0.1s ease;
    line-height: 1.6em;
    padding: 13px;
    width: 99.8%;
}

.field {
    padding-bottom: 25px;
}

.field label {
    margin-bottom: 5px;
}

.pac-container {
    background-color: #fff;
    position: absolute !important;
    z-index: 1000;
    border-radius: 2px;
    border-top: 1px solid #d9d9d9;
    font-family: Arial, sans-serif;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
}

.pac-container:after {
    content: "";
    padding: 1px 1px 1px 0;
    height: 16px;
    text-align: right;
    display: block;
    background: url(https://developers.google.com/maps/documentation/places/images/powered-by-google-on-white.png)
        no-repeat center right cover;
}

.pac-logo:after {
    content: "";
    padding: 1px 1px 1px 0;
    height: 16px;
    text-align: right;
    display: block;
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: 120px 14px;
}
.hdpi.pac-logo:after {
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png);
}
.pac-item {
    cursor: default;
    padding: 0 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    border-top: 1px solid #e6e6e6;
    font-size: 11px;
    color: #999;
}
.pac-item:hover {
    background-color: #fafafa;
}
.pac-item-selected,
.pac-item-selected:hover {
    background-color: #ebf2fe;
}
.pac-matched {
    font-weight: 700;
}
.pac-item-query {
    font-size: 13px;
    padding-right: 3px;
    color: #000;
}
.pac-icon {
    width: 15px;
    height: 20px;
    margin-right: 7px;
    margin-top: 6px;
    display: inline-block;
    vertical-align: top;
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
    background-size: 34px;
}
.hdpi .pac-icon {
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons_hdpi.png);
}
.pac-icon-search {
    background-position: -1px -1px;
}
.pac-item-selected .pac-icon-search {
    background-position: -18px -1px;
}
.pac-icon-marker {
    background-position: -1px -161px;
}
.pac-item-selected .pac-icon-marker {
    background-position: -18px -161px;
}
.pac-placeholder {
    color: gray;
}
</style>
