<template>
  <div
    id="gf-epl-address-details"
  >
    <div class="row">
      <div
        v-show="settings.fields.addressLine1.isVisible"
        class="col-12 pb-4"
      >
        <Textbox
          :id="settings.fields.addressLine1.id"
          :value="settings.fields.addressLine1.value"
          :name="settings.fields.addressLine1.name"
          :placeholder="settings.fields.addressLine1.placeholder"
          :is-required="settings.fields.addressLine1.isRequired"
          @valueChanged="onAddressLine1Changed"
        />
      </div>

      <div
        v-show="settings.fields.addressLine2.isVisible"
        class="col-12 pb-4"
      >
        <Textbox
          :id="settings.fields.addressLine2.id"
          :value="settings.fields.addressLine2.value"
          :name="settings.fields.addressLine2.name"
          :placeholder="settings.fields.addressLine2.placeholder"
          :is-required="settings.fields.addressLine2.isRequired"
          @valueChanged="onAddressLine2Changed"
        />
      </div>
      <div
        v-show="settings.fields.suburb.isVisible"
        class="col-md-6 col-sm-6 col-6"
      >
        <div class="ui input m-0 pb-4">
          <Textbox
            :id="settings.fields.suburb.id"
            :value="settings.fields.suburb.value"
            :name="settings.fields.suburb.name"
            :placeholder="settings.fields.suburb.placeholder"
            :is-required="settings.fields.suburb.isRequired"
            @valueChanged="onSuburbChanged"
          />
        </div>
      </div><!-- .col-6 -->
      <div
        v-show="settings.fields.postcode.isVisible"
        class="col-md-6 col-sm-6 col-6"
      >
        <div>
          <div class="ui input m-0 pb-4">
            <Textbox
              :id="settings.fields.postcode.id"
              :value="settings.fields.postcode.value"
              :name="settings.fields.postcode.name"
              :placeholder="settings.fields.postcode.label"
              :is-required="settings.fields.postcode.isRequired"
              @valueChanged="onPostcodeChanged"
            />
          </div>
        </div><!-- .field -->
      </div><!-- .col-6 -->
      <div
        v-show="settings.fields.state.isVisible"
        class="col-md-6 col-sm-6 col-6"
      >
        <div>
          <div class="ui input m-0">
            <Textbox
              :id="settings.fields.state.id"
              :value="settings.fields.state.value"
              :name="settings.fields.state.name"
              :placeholder="settings.fields.state.label"
              :is-required="settings.fields.state.isRequired"
              @valueChanged="onStateChanged"
            />
          </div>
        </div><!-- .field -->
      </div><!-- .col-6 -->
      <div
        v-show="settings.fields.country.isVisible"
        class="col-md-6 col-sm-6 col-6"
      >
        <div class="ui input m-0">
          <Textbox
            :id="settings.fields.country.id"
            :value="settings.fields.country.value"
            :name="settings.fields.country.name"
            :placeholder="settings.fields.country.placeholder"
            :is-required="settings.fields.state.isRequired"
            @valueChanged="onCountryChanged"
          />
        </div>
      </div><!-- .col-6 -->
    </div><!-- .row -->
  </div><!-- #gf-epl-address-details -->
</template>
<script>
import ModuleBase from "../Common/ModuleBase.vue";
import Textbox from "../Common/Textbox.vue";
export default {
    components: {
      Textbox
    },
    components: { Textbox },
    mixins: [ModuleBase],
    methods: {
      onAddressLine1Changed(val) {
        this.$emit('addressLine1Changed', val);
      },

      onAddressLine2Changed(val) {
        this.$emit('addressLine2Changed', val);
      },

      onSuburbChanged(val) {
        this.$emit('suburbChanged', val);
      },

      onPostcodeChanged(val) {
        this.$emit('postcodeChanged', val);
      },

      onStateChanged(val) {
        this.$emit('stateChanged', val);
      },

      onCountryChanged(val) {
        this.$emit('countryChanged', val);
      }
    }
}
</script>
