<template>
  <input
    :id="id"
    v-model="value"
    :name="name"
    type="text"
    :placeholder="placeholder"
    autocomplete="off"
    :class="`${isRequired ? validationClass:''}`"
    @blur="validate(value)"
  >
</template>

<script>

export default {
    props: {
        value: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        isRequired:{
            type: Boolean,
            default: false
        }
    },
    data() {
      return {
            validationClass:  'is-invalid',
        }
    },
    watch:{
        value:{
            handler(val){
                this.$nextTick(() => {
                    this.validate(val);
                })
            },
            deep:true
        }
    },
    methods:{
      validate(val){
        this.$emit('valueChanged', val);
        if(!this.isRequired)
        {
            return;
        }

        if(val !== ''){
          this.validationClass = 'is-valid';
        }
        else{
          this.validationClass = 'is-invalid';
        }
      }
    },
}
</script>
