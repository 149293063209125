<template>
  <div>
    <google-lookup-wrapper
      v-model="updatedValue"
      :settings="settings"
      @loadError="onLoadError"
      @selected="addressSelected"
    />
  </div>
</template>
<script>
import ModuleBase from "../../../Common/ModuleBase.vue";
import LookupBase from '../LookupBase.vue';
import GoogleLookupWrapper from './GoogleLookupWrapper.vue'
export default {
  name: "GoogleLookup",
  components: {
      GoogleLookupWrapper
  },
  mixins: [ModuleBase, LookupBase],
  data(){
      return{
          updatedValue:''
      }
  },
  watch:{
    inputAddress:{
      handler(val){
        this.$nextTick(() => {
            this.updatedValue = val;
        })
      },
      deep:true
    }
  },
  methods: {
    addressSelected(place){
        this.address = {};
        const componentForm = {
            street_number: "short_name",
            route: "long_name",
            administrative_area_level_1: "short_name",
            country: "long_name",
            postal_code: "short_name",
            subpremise:"short_name",
            sublocality_level_1:"short_name",
            locality:"short_name",
            postal_town: "long_name"
        };

        var subLocalityAvailable = false;
        var localityAvailable = false;
        for (const component of place.address_components) {
          const addressType = component.types[0];

          if (typeof componentForm[addressType] != 'undefined' && typeof component[componentForm[addressType]] != 'undefined') {
            const val = component[componentForm[addressType]];

            switch(addressType) {
              case 'street_number': this.address.addressLine1 = val + ' '; break;
              case 'route': this.address.addressLine1 = this.address.addressLine1 + val; break;
              case 'administrative_area_level_1': this.address.state = val; break;
              case 'country': this.address.country = val; break;
              case 'postal_code': this.address.postcode = val; break;
              case 'subpremise': this.address.addressLine2 = val; break;
              case 'sublocality_level_1':
                this.address.suburb = val;
                subLocalityAvailable = true;
                break;
              case 'locality':
                if(!subLocalityAvailable) {
                  this.address.suburb = val;
                  localityAvailable = true;
                }
                break;
              case 'postal_town':
                if (!subLocalityAvailable && !localityAvailable)
                  this.address.suburb = val;
                break;
            }
          }
        }
    },

    onLoadError() {
        this.$emit('loadError')
    }
  }
}
</script>

<style>
#gf-epl-address-details input[type="text"] {
	margin: 0;
  outline: 0;
  tap-highlight-color: hsla(0,0%,100%,0);
  border: 1px solid rgba(34,36,38,.2);
  color: #000;
  border-radius: 5px;
  -webkit-box-shadow: 0 0 0 0 transparent inset;
  box-shadow: inset 0 0 0 0 transparent;
  -webkit-transition: color .1s ease,border-color .1s ease;
  transition: color .1s ease,border-color .1s ease;
  line-height: 1.6em;
  padding: 13px;
	width: 99.8%;
}

.field{
	padding-bottom: 25px;
}

.field label{
	margin-bottom: 5px;
}

.pac-container {
    background-color: #fff;
    position: absolute!important;
    z-index: 1000;
    border-radius: 2px;
    border-top: 1px solid #d9d9d9;
    font-family: Arial, sans-serif;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden
}

.pac-container:after{
    content:"";
    padding:1px 1px 1px 0;
    height:16px;
    text-align:right;
    display:block;
    background: url(https://developers.google.com/maps/documentation/places/images/powered-by-google-on-white.png) no-repeat center right cover;
}

.pac-logo:after {
    content: "";
    padding: 1px 1px 1px 0;
    height: 16px;
    text-align: right;
    display: block;
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3.png);
    background-position: right;
    background-repeat: no-repeat;
    background-size: 120px 14px
}
.hdpi.pac-logo:after {
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/powered-by-google-on-white3_hdpi.png)
}
.pac-item {
    cursor: default;
    padding: 0 4px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 30px;
    text-align: left;
    border-top: 1px solid #e6e6e6;
    font-size: 11px;
    color: #999
}
.pac-item:hover {
    background-color: #fafafa
}
.pac-item-selected,
.pac-item-selected:hover {
    background-color: #ebf2fe
}
.pac-matched {
    font-weight: 700
}
.pac-item-query {
    font-size: 13px;
    padding-right: 3px;
    color: #000
}
.pac-icon {
    width: 15px;
    height: 20px;
    margin-right: 7px;
    margin-top: 6px;
    display: inline-block;
    vertical-align: top;
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons.png);
    background-size: 34px
}
.hdpi .pac-icon {
    background-image: url(https://maps.gstatic.com/mapfiles/api-3/images/autocomplete-icons_hdpi.png)
}
.pac-icon-search {
    background-position: -1px -1px
}
.pac-item-selected .pac-icon-search {
    background-position: -18px -1px
}
.pac-icon-marker {
    background-position: -1px -161px
}
.pac-item-selected .pac-icon-marker {
    background-position: -18px -161px
}
.pac-placeholder {
    color: gray
}
</style>
