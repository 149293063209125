<script>
export default {
    props: {
        inputAddress: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            address:{
                addressLine1: '',
                addressLine2: '',
                suburb: '',
                postcode: '',
                state: '',
                country: '',
            }
        }
    },
    watch:{
        address:{
            deep: true,
            handler(val){
                this.$_LookupBase_AddressSelected()
            }    
        }
    },
    methods: {
        $_LookupBase_AddressSelected(){
            this.$emit('addressSelected',this.address)
        }
    }
}
</script>