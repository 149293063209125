<script>
import logger from "../../common/logger";
import SimpleVueValidator from 'simple-vue-validator';
import CommonComponentStore from '../../CommonComponentStore'

export default {
    props: {
        settings: {
            type: Object,
            default: () => null
        },
    },
    data() {
        return {
            logger: logger,
            address: '',
            store: CommonComponentStore,
            isValid: true,
            validator: SimpleVueValidator.Validator
        }
    },
    watch: {
        isValid(val) {
            var self = this;            
            this.$nextTick(async () => await self.store.validate());
        },
    },
    mounted() {
        this.uniqueId = this._uid;
        this.$nextTick(() => {
            this.store.mountedComponent(this)
        });
    },
    methods: {
        async $ModuleBase_validate() {            
           return true;
        },
    }
}
</script>