import logger from '../src/common/logger';

export default {
    state: {
        isDemo: false,
        canProceed: false,
        modules: [],
    },

    setDemo(isDemo) {
        this.state.isDemo = isDemo;
    },

    mountedComponent(component) {
        logger.logInfo(`mounted component `, component);
        this.state.modules.push({
            module: component
        });
        this.validate();
    },

    async validate() {
        logger.logInfo(`validate, currentPageModules:`, this.state.modules);

        for (var i = 0; i < this.state.modules.length; i++) {
            if (!(await this.state.modules[i].module.$ModuleBase_validate())) {
                this.state.canProceed = false;
                return;
            }
        }

        this.state.canProceed = !(this.state.modules.find(m => !m.module.isValid));
        logger.logInfo(`validate, currentPageIsValid: ${this.state.canProceed}`);
    },
}